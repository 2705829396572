import { appConfig } from '@/config/appConfig';
import { createHttp } from '@/api/HttpHelper';
const axios = createHttp(appConfig.base2GUrl)

/**
 * 获取ACC报表数据
 * @param {JSON} val 
 */
export const chargeAndGift = (val) => { return axios.get('rechargeInternel/chargeAndGift.do', { params: val }) }

/**
 * 查询充值记录
 * @param {JSON} val 
 */
export const queryChargeLog = (val) => { return axios.get('rechargeInternel/queryChargeLog.do', { params: val }) }

/**
 * 查询充值记录
 * @param {JSON} val 
 */
export const queryCustomUser = () => { return axios.get('user/queryCustomUser.do') }

