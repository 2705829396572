export const generateVCode = (type, len = 4) => {
  let options = [
    "azxcvbnmsdfghjklqwertyuiopZXCVBNMASDFGHJKLQWERTYUIOP",
    "0123456789"
  ];
  let all = options[type] || options.join("");
  let b = "";
  for (let i = 0; i < len; i++) {
    let index = Math.floor(Math.random() * all.length);
    b += all.charAt(index);
  }
  return b;
};