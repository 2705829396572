<template>
  <div class="GiveSubcomponent">
    <el-row>
      <el-col :span="5">
        <label>客户代码</label>
        <el-select
          v-model="customCode"
          style="width:calc(100% - 80px)"
          filterable
          clearable
          placeholder="请搜索或选择用户代码"
          @focus="CLickUser"
          @change="ChangeUser"
          @clear="clear"
        >
          <el-option
            v-for="(item,index) in customerDataList"
            :key="index"
            :label="item.name"
            :value="item"
          >
            <span>{{`客户代码：${item.customCode}， 用户名：${item.name}， 公司名：${item.corpName}`}}</span>
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <label>赠送授权币数</label>
        <el-input
          v-model="searchParams.moneyGive"
          style="width:calc(100% - 100px)"
          @keyup.native="searchParams.moneyGive = searchParams.moneyGive.replace(/[^0-9]/g,'')"
        ></el-input>
      </el-col>
      <el-col :span="8">
        <label>备注</label>
        <el-input
          style="width:calc(100% - 100px)"
          v-model="searchParams.remark"
        ></el-input>
      </el-col>
      <el-col :span="5">
        <label>验证码</label>
        <el-button
          type="text"
          v-text="getVerificationCode"
          class="verCode"
          @click="getVerCode"
        ></el-button>
        <el-input
          v-model="verificationCode"
          style="width:calc(100% - 180px);position:relative;bottom:3px"
        ></el-input>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="Recharge">赠送</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// 获取用户，用户代码接口
import {
  queryCustomUser,
  getManageUserInfo,
} from '@/api/getManagementData.js';
// 充值
import { chargeAndGift } from '@/api/getRecharge.js';

// 验证码
import { generateVCode } from '@/utils/generateVCode';

export default {
  name: 'GiveSubcomponent',
  data() {
    return {
      activeName: "", // 标签页切换
      customerDataList: [], // 客户数据集合
      customCode: "",// 客户代码
      searchParams: { // 搜索参数
        userId: "", //客户代码
        chargeCount: 0, // 充值授权币数
        price: 0, // 充值金额
        remark: "", //输入备注
        moneyGive: 0,//赠送车币数
        type: 2,
      },
      verificationCode: "", // 输入验证码
      getVerificationCode: "", // 获取验证码
    };
  },
  methods: {
    //点击用户选择
    CLickUser() {
      this.searchUserCode();// 重新获取用户集合数据
    },
    ChangeUser(row) { // select选择,将客户数据赋值
      this.searchParams.userId = row.userId;
      this.customCode = row.customCode;
    },
    clear() {
      //初始化
      this.customerDataList = [];
      this.searchParams.userId = "";
      this.customCode = "";
    },

    //进入时获取用户代码级别的用户
    async searchUserCode() {
      try {
        const resData = await queryCustomUser(); // 通过接口获取数据
        this.customerDataList = resData.obj.filter(p => p.customCode); //用户集合获取
      } catch (error) {
        this.$message.warning("查询异常");
        console.log(error);
      }
    },

    // 获取验证码
    getVerCode() {
      let code = generateVCode();
      this.getVerificationCode = code;
      this.verificationCode = "";
    },

    // 点击充值
    async Recharge() {
      if (!this.verificationRecharge()) return;
      try {
        const res = await chargeAndGift({ ...this.searchParams });
        this.$emit("giveData", res);
        this.$message.info(res.msg);
      } catch (error) {
        this.$message.warning("查询异常");
        console.log(error);
      }
      this.getVerCode();
    },
    //验证充值信息
    verificationRecharge() {
      if (!this.searchParams.userId) {
        this.$message.warning("请输入客户代码！");
        return false;
      }
      if (!this.searchParams.moneyGive) {
        this.$message.warning("请输入赠送币数！");
        return false;
      }

      if (!this.verificationCode) {
        this.$message.warning("请输入验证码信息！");
        return false;
      }

      if (this.verificationCode.toLowerCase() !== this.getVerificationCode.toLowerCase()) {
        this.$message.warning("验证码错误!");
        return false;
      }

      return true;
    }
  },

  mounted() {
    this.searchUserCode();// 获取用户数据
    this.getVerCode();// 获取验证码
  }
}
</script>

<style lang="scss" scoped>
.GiveSubcomponent {
  font-size: 14px;
  .el-col {
    > label {
      margin-right: 10px;
    }
  }
  .verCode {
    width: 90px;
    height: 32px;
    margin-right: 10px;
    text-align: center;
    background-color: hsla(182, 65%, 19%, 0.13);
    color: #3c4e088c;
    font-size: 16px;
    letter-spacing: 6px;
    cursor: pointer;
  }
}
</style>