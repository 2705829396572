<template>
  <el-container class="Rechargeing">
    <el-header height="100px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="充值">
          <RechargeingSubcomponent @rechargeData="rechargeData"></RechargeingSubcomponent>
        </el-tab-pane>
        <el-tab-pane label="赠送">
          <GiveSubcomponent @giveData="giveData"></GiveSubcomponent>
        </el-tab-pane>
      </el-tabs>
    </el-header>
    <el-main>
      <el-table stripe size="mini" :data="data" height="100%">
        <el-table-column prop="rechargeType" label="类型">
          <template slot-scope="scope">
            <span v-text="scope.row.rechargeType ==1?'充值':'赠送'"></span>
          </template>
        </el-table-column>
        <el-table-column prop="userCode" label="客户代码"></el-table-column>
        <el-table-column prop="price" label="充值金额"></el-table-column>
        <el-table-column prop="chargeCount" label="充值授权币"></el-table-column>
        <el-table-column prop="moneyGive" label="赠送授权币"></el-table-column>
        <el-table-column prop="moneyBefore" label="充值前授权币数"></el-table-column>
        <el-table-column prop="moneyAfter" label="充值后授权币数"></el-table-column>
        <el-table-column prop="ctime" label="操作时间"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
      </el-table>
    </el-main>
  </el-container>
</template>

<script>
// 充值子组件
import RechargeingSubcomponent from "@/views/Recharge/RechargeingSubcomponent.vue";
// 赠送子组件
import GiveSubcomponent from "@/views/Recharge/GiveSubcomponent.vue";

export default {
  name: 'Rechargeing',
  components: {
    RechargeingSubcomponent,
    GiveSubcomponent
  },
  data() {
    return {
      activeName: '',
      data: [],
    };
  },
  methods: {
    rechargeData(val) {
      this.data.unshift(val.obj);
    },
    giveData(val) {
      this.data.unshift(val.obj);
    }
  }
};
</script>

<style lang="scss" scoped>
.Rechargeing {
  height: 100%;
  .el-main {
    height: calc(100% - 100px);
  }
}
</style>