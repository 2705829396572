<template>
  <div class="StartRecharge">
    <el-tabs type="card" v-model="activeName">
      <el-tab-pane label="授权">
        <Rechargeing></Rechargeing>
      </el-tab-pane>
      <el-tab-pane label="授权记录">
        <RechargeRecord></RechargeRecord>
      </el-tab-pane>
    </el-tabs>
    <!-- 退出按钮 -->
    <el-button
      type="primary"
      style="position:absolute;top:5px;right:20px"
      @click="signOut"
    >退出</el-button>

    <el-dialog title="温馨提示" :visible.sync="dialogVisible" width="400px">
      <p>
        <span style="color: red;">授权</span>后将不可撤回，请谨慎操作！
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">我知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 授权
import Rechargeing from '@/views/Recharge/Rechargeing';
// 授权记录
import RechargeRecord from '@/views/Recharge/RechargeRecord';
export default {
  name: 'StartRecharge',
  data() {
    return {
      activeName: "",
      dialogVisible: true,
    };
  },
  components: {
    Rechargeing,
    RechargeRecord
  },
  methods: {
    signOut() {
      this.$router.push('/login');// 跳转至登录开始页面
    }
  },
}
</script>

<style lang="scss" scoped>
.StartRecharge {
  height: 100%;
  background-color: #ffffff;
  position: relative;
  ::v-deep .el-tabs {
    height: 100%;
    .el-tabs__content {
      height: calc(100% - 56px);
      .el-tab-pane {
        height: 100%;
      }
    }
  }
}
</style>