<template>
  <el-container class="RechargeRecord">
    <el-header height="auto">
      <div class="filter-pane">
        <label>客户代码</label>
        <el-select
          v-model="customCode"
          style="width:220px"
          filterable
          clearable
          placeholder="请搜索或选择用户代码"
          @focus="CLickUser"
          @change="ChangeUser"
          @clear="clear"
        >
          <el-option
            v-for="(item,index) in customerDataList"
            :key="index"
            :label="item.name"
            :value="item"
          >
            <span>{{`客户代码：${item.customCode}， 用户名：${item.name}， 公司名：${item.corpName}`}}</span>
          </el-option>
        </el-select>

        <label>选择时间</label>
        <reportDate size="small" @change="onChoiceDate"></reportDate>

        <el-button
          style="font-size: 14px;height:32px;width:90px;margin-left:20px;"
          type="primary"
          icon="ios-search"
          @click="onSearchClick()"
        >搜索</el-button>
        <!-- 导出 -->
        <BtnExport
          :disabled="!data>0"
          title="授权记录"
          exportUrl="rechargeInternel/queryChargeLog.do"
          exportDataType="1"
          :params="{
            ...this.searchParams,
            pageSize:5000,
          }"
        />
      </div>
    </el-header>
    <el-main>
      <el-table
        class="tableExcel"
        stripe
        size="mini"
        :data="data"
        height="100%"
      >
        <el-table-column prop="rechargeType" label="类型">
          <template slot-scope="scope">
            <span v-text="scope.row.rechargeType ==1?'充值':'赠送'"></span>
          </template>
        </el-table-column>
        <el-table-column prop="userCode" label="客户代码"></el-table-column>
        <el-table-column prop="price" label="充值金额"></el-table-column>
        <el-table-column prop="chargeCount" label="充值授权币"></el-table-column>
        <el-table-column prop="moneyGive" label="赠送授权币"></el-table-column>
        <el-table-column prop="moneyBefore" label="充值前授权币数"></el-table-column>
        <el-table-column prop="moneyAfter" label="充值后授权币数"></el-table-column>
        <el-table-column prop="ctime" label="充值时间"></el-table-column>
        <el-table-column prop="remark" label="赠送说明"></el-table-column>
      </el-table>
    </el-main>
  </el-container>
</template>

<script>
//选择时间
import reportDate from "@/components/Date/reportDate";
//导出组件
import BtnExport from "@/components/Export";
// 获取用户，用户代码接口
import { queryCustomUser } from '@/api/getManagementData.js';
// 查询用户充值与赠送情况
import { queryChargeLog } from '@/api/getRecharge.js';
export default {
  data() {
    return {
      activeName: "", // 标签页切换
      customerDataList: [], // 客户数据集合
      customCode: "",// 客户代码
      searchParams: { // 搜索参数
        userId: "", // 客户代码
        startTime: "",// 开始时间
        endTime: "", // 结束时间
      },
      data: [],
    };
  },
  components: {
    reportDate,
    BtnExport,
  },
  methods: {
    //点击用户选择
    CLickUser() {
      this.searchUserCode();// 重新获取用户集合数据
    },
    ChangeUser(row) { // select选择,将客户数据赋值
      this.searchParams.userId = row.userId;
      this.customCode = row.customCode;
    },
    clear() {
      //初始化
      this.customerDataList = [];
      this.searchParams.userId = "";
      this.customCode = "";
    },

    //进入时获取用户代码级别的用户
    async searchUserCode() {
      try {
        const resData = await queryCustomUser(); // 通过接口获取数据
        this.customerDataList = resData.obj.filter(p => p.customCode); //用户集合获取
      } catch (error) {
        this.$message.warning("查询异常");
        console.log(error);
      }
    },

    onChoiceDate(dates) {//选择时间
      this.searchParams.startTime = '';
      this.searchParams.endTime = '';
      if (dates.length === 0) return this.$message.warning('请选择正确的时间');
      if (dates.length === 2) {
        this.searchParams.startTime = dates[0];
        this.searchParams.endTime = dates[1];
      }
    },

    async onSearchClick() {
      try {
        const res = await queryChargeLog({ ...this.searchParams });
        this.data = res.obj.list;
        if (res.obj.list.length == 0) {
          this.$message.warning("数据查询为空");
        }
      } catch (error) {
        this.$message.warning('查询异常');
        console.log(error);
      }
    },
  },
  mounted() {
    this.searchUserCode();// 获取用户数据
  }
}
</script>

<style lang="scss" scoped>
@import 'src/style/reportStyle.scss';
.RechargeRecord {
  height: 100%;
}
</style>